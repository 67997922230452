import React, { useState } from 'react';
import schema from '../../components/data/info/crate.json'
const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  // Validation function that returns error messages
  const validateFormData = (formData, schema) => {
    const errors = {};
    for (const [key, { type, title }] of Object.entries(schema.properties)) {
      if (schema.required.includes(key) && !formData[key]) {
        errors[key] = `${title} is required.`;
      } else if (type === 'email' && formData[key] && !isValidEmail(formData[key])) {
        errors[key] = 'Email is not valid.';
      } else if (type === 'string' && key === 'password' && formData[key] && formData[key].length < 6) {
        errors[key] = 'Password must be at least 6 characters long.';
      }
    }
    return errors;
  };

  function DynamicForm({ }) {
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData(prevState => ({ ...prevState, [name]: value }));
      // Optionally clear errors on change or keep until re-validation
      // setErrors(prevErrors => ({ ...prevErrors, [name]: undefined }));
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      const newErrors = validateFormData(formData, schema);
      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
      } else {
        console.log("Form data submitted:", formData);
        setErrors({}); // Clear errors if submission is successful
      }
    };

    return (
      <form onSubmit={handleSubmit}>
        <h2>{schema.title}</h2>
        {Object.entries(schema.properties).map(([key, { type, title }]) => (
          <div key={key}>
            <label htmlFor={key}>{title}:</label>
            <input
              type={type === 'password' ? 'password' : 'text'}
              id={key}
              name={key}
              value={formData[key] || ''}
              onChange={handleChange}

              className="border m-0.5 text-sm text-neutral-500 appearance-none pr-[180px] rounded-md py-2 pl-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            />
            {errors[key] && <div style={{ color: 'red' }}>{errors[key]}</div>}
          </div>
        ))}
        <button type="submit">Submit</button>
      </form>
    );
  }

  export default DynamicForm;