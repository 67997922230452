import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import { AddCircleOutlineOutlined, DeleteOutlineOutlined, EditOutlined, FiberManualRecord, Person, RemoveCircleOutlineOutlined, VisibilityOutlined } from "@mui/icons-material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import UserProfile from "./common/UserProfile";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteUser, updateUserStatus, setCurrentUser } from "state/userSlice";
import ConfirmationModal from "./common/ConfirmationModal";
import { ToastContainer, toast } from "react-toastify";


const ManageUsers = () => {
  const allUsers = useSelector((state) => state.users.users);
  const [searchFocus, setSearchFocus] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");
  const [showEntriesDropdown, setShowEntriesDropdown] = useState(false);
  const [isDeactivateModalOpen, setIsDeactivateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [userToModify, setUserToModify] = useState(null); // For tracking the user to deactivate/delete
  const currentUser = useSelector((state) => state.users.currentUser);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const filteredUsers = allUsers.filter(
    (user) =>
      user.personalDetails.firstName
        ?.toLowerCase()
        .includes(searchTerm?.toLowerCase()) ||
      user.personalDetails.lastName
        ?.toLowerCase()
        .includes(searchTerm?.toLowerCase()) ||
      user.personalDetails.email
        ?.toLowerCase()
        .includes(searchTerm?.toLowerCase())
  );

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(filteredUsers.length / entriesPerPage);

  const handleViewProfile = (user) => {
    setSelectedUser(user);
  };

  const handleCloseProfile = () => {
    setSelectedUser(null);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleEntriesPerPageChange = (value) => {
    setEntriesPerPage(Number(value));
    setCurrentPage(1);
    setShowEntriesDropdown(false);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 7;
    let startPage = Math.max(1, currentPage - 3);
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-3 py-1 mx-1 rounded ${
            currentPage === i
              ? "bg-blue-500 text-white"
              : "text-gray-700 hover:bg-gray-200"
          }`}
        >
          {i}
        </button>
      );
    }

    return pageNumbers;
  };

  const handleEditUser = (user) => {
    dispatch(setCurrentUser(user));
    navigate(`/users/add/?edit=true`);
  };

  const handleToggleUserStatus = (user) => {
    if (user.personalDetails?.status === 'Active') {
      setUserToModify(user); // Set the user to deactivate
      setIsDeactivateModalOpen(true); // Open the modal
    } else {
      // Directly activate the user without modal
      const newStatus = 'Active';
      dispatch(
        updateUserStatus({
          userId: user.personalDetails.id,
          status: newStatus,
        })
      );
      toast.success(`User details for ${user.personalDetails.firstName} ${user.personalDetails.lastName} have been activated`);
    }
  };
  
  const confirmDeactivateUser = () => {
    const newStatus = 'Inactive';
    dispatch(
      updateUserStatus({
        userId: userToModify.personalDetails.id,
        status: newStatus,
      })
    );
    setIsDeactivateModalOpen(false); // Close the modal
    toast.warn(`User details for ${userToModify.personalDetails.firstName} ${userToModify.personalDetails.lastName} have been deactivated`);
    setUserToModify(null); // Clear the user
  };
  

  const handleDeleteUser = (user) => {
    setUserToModify(user); // Set the user to delete
    setIsDeleteModalOpen(true); // Open the delete confirmation modal
  };

  const confirmDeleteUser = () => {
    dispatch(deleteUser(userToModify.personalDetails.id)); // Dispatch delete action
    setIsDeleteModalOpen(false); // Close the modal
    toast.error(`User account "${userToModify.personalDetails.firstName} ${userToModify.personalDetails.lastName}" has been deleted`);
    setUserToModify(null); // Clear the user
  };

  return (
    <>
    <ToastContainer />
      <div className="w-full px-6 py-2 border-b border-[#edeae9] flex justify-between items-center">
        <h2 className="text-[22px] font-medium font-['Manrope'] py-2">Users</h2>
      </div>
      <div className="flex justify-between items-start h-[calc(100vh-200px)]">
        <div
          className={`pb-4 pt-2 border-2 border-[#edeae9] rounded-lg m-8 transition-all ${
            selectedUser ? "w-[65%]" : "w-full"
          } h-full overflow-hidden flex flex-col`}
        >
          <div className="py-4">
            <div className="flex justify-between">
              <div className="flex">
                <div className="px-4">
                  <h3 className="text-lg font-medium font-['Manrope']">
                    Users
                  </h3>
                  <p className="text-sm font-normal font-['Manrope'] text-[#667084]">
                    List of users in the organization
                  </p>
                </div>
              </div>
              <div className="relative w-[320px] pr-4">
                <input
                  type="text"
                  placeholder="Search"
                  className="pr-3 pl-6 py-2 border rounded-md w-full focus:outline-none placeholder:pl-8"
                  onFocus={() => setSearchFocus(true)}
                  onBlur={() => setSearchFocus(false)}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  value={searchTerm}
                  style={{
                    transition: "padding-left 300ms",
                    paddingLeft: searchFocus ? "1rem" : "0.625rem",
                  }}
                />
                <SearchIcon
                  className="absolute left-0 top-1/2 -translate-y-1/2"
                  style={{
                    transition: "left 300ms",
                    left: searchFocus || searchTerm ? "17rem" : "0.625rem",
                    top: "1.3rem",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="overflow-y-auto flex-grow">
            <table className="min-w-full shadow rounded-lg">
              <thead className="sticky top-0 bg-white">
                <tr className="gradient-background py-4 rounded-md">
                  <th className="px-5 py-3 border-b-2 text-left text-xs font-semibold tracking-wider">
                    User
                  </th>
                  <th className="px-5 py-3 border-b-2 text-left text-xs font-semibold tracking-wider">
                    Role
                  </th>
                  <th className="px-5 py-3 border-b-2 text-left text-xs font-semibold tracking-wider">
                    Status
                  </th>
                  <th className="px-5 py-3 border-b-2 text-left text-xs font-semibold tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentUsers.map((user, index) => (
                  <tr key={index}>
                    <td className="px-5 py-2 border-b bg-white text-sm w-1/2">
                      <div className="flex items-center">
                        <Person className="text-gray-500" />
                        <div className="flex items-center">
                          <div className="ml-3">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {user.personalDetails.firstName}{" "}
                              {user.personalDetails.lastName}
                            </p>
                            <p className="text-gray-600 whitespace-no-wrap">
                              {user.personalDetails.email}
                            </p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-5 py-2 border-b bg-white text-sm w-1/6">
                      {user.personalDetails.roleType}
                    </td>
                    <td className="px-5 py-2 border-b bg-white text-sm w-1/6">
                      <div
                        className={`w-[85px] flex justify-around items-center px-3 py-1 font-semibold leading-tight ${
                          user.personalDetails.status === "Active"
                            ? "text-green-700 bg-green-100"
                            : "text-gray-700 bg-gray-200"
                        } rounded-full`}
                      >
                        <FiberManualRecord style={{ fontSize: "10px" }} />
                        {user.personalDetails.status}
                      </div>
                    </td>
                    <td className="px-5 py-5 border-b bg-white text-sm w-1/6">
                      <div className="flex justify-around items-center space-x-2">
                        <button
                          className="text-gray-600 hover:text-gray-800"
                          onClick={() => handleViewProfile(user)}
                        >
                          <VisibilityOutlined className="h-3 w-3" />
                        </button>
                        <button
                          className="text-gray-600 hover:text-gray-800"
                          onClick={() => handleEditUser(user)}
                        >
                          <EditOutlined className="h-3 w-3" />
                        </button>
                        <button
                          className="text-gray-600 hover:text-gray-800"
                          onClick={() => handleToggleUserStatus(user)}
                        >
                          {user.personalDetails.status === "Active" ? (
                            <RemoveCircleOutlineOutlined className="h-3 w-3" />
                          ) : (
                            <AddCircleOutlineOutlined className="h-3 w-3" />
                          )}
                        </button>
                        <button
                          className="text-gray-600 hover:text-gray-800"
                          onClick={() => handleDeleteUser(user)}
                        >
                          <DeleteOutlineOutlined className="h-3 w-3" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mt-2 flex items-center justify-center space-x-3 px-4 text-sm">
            <div className="flex items-center space-x-1">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="p-1 rounded-full text-gray-600 hover:bg-gray-200 disabled:text-gray-300 disabled:hover:bg-white"
              >
                <ChevronLeftIcon fontSize="small" />
              </button>
              {renderPageNumbers()}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="p-1 rounded-full text-gray-600 hover:bg-gray-200 disabled:text-gray-300 disabled:hover:bg-white"
              >
                <ChevronRightIcon fontSize="small" />
              </button>
            </div>
            <div className="relative">
              <button
                className="border rounded px-3 py-1.5 flex items-center justify-between min-w-[120px]"
                onClick={() => setShowEntriesDropdown(!showEntriesDropdown)}
              >
                <span>{entriesPerPage} per page</span>
                <ArrowDropDownIcon fontSize="small" />
              </button>
              {showEntriesDropdown && (
                <div className="absolute bottom-full right-0 bg-white border rounded mb-1 z-10 shadow-md w-full">
                  {[5, 10, 15, 20].map((value) => (
                    <button
                      key={value}
                      className="block w-full text-left px-3 py-1.5 hover:bg-gray-100"
                      onClick={() => handleEntriesPerPageChange(value)}
                    >
                      {value} per page
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        {selectedUser && (
          <div className="w-[30%] h-full overflow-y-auto mt-8 mr-2 custom-hide-scrollbar">
            <UserProfile user={selectedUser} onClose={handleCloseProfile} />
          </div>
        )}
      </div>

      {/* Deactivate User Modal */}
      <ConfirmationModal
        isOpen={isDeactivateModalOpen}
        onClose={() => setIsDeactivateModalOpen(false)}
        onConfirm={confirmDeactivateUser}
        message="Are you sure you want to deactivate this user"
        userName={
          userToModify
            ? `${userToModify.personalDetails.firstName} ${userToModify.personalDetails.lastName}`
            : ""
        }
        confirmText="Deactivate User"
        confirmColor="bg-yellow-500"
        confirmHoverColor="bg-yellow-600"
      />

      {/* Delete User Modal */}
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={confirmDeleteUser}
        message="Are you sure you want to delete this user? This action is irreversible."
        userName={
          userToModify
            ? `${userToModify.personalDetails.firstName} ${userToModify.personalDetails.lastName}`
            : ""
        }
        confirmText="Delete"
        confirmColor="bg-red-600"
        confirmHoverColor="bg-red-700"
      />
    </>
  );
};

export default ManageUsers;
