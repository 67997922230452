import React from "react";
import { Close as CloseIcon, CorporateFareOutlined, LocationOnOutlined, SecurityOutlined } from "@mui/icons-material";
import { CheckCircle as CheckCircleIcon } from "@mui/icons-material"; // Import check icon for module permissions

const UserProfile = ({ onClose, user }) => {
  return (
    <div className="relative bg-white p-6 border rounded-lg shadow-lg max-w-md w-full h-[78vh] overflow-y-auto custom-hide-scrollbar">
      {/* Close button in top-right corner */}
      <button onClick={onClose} className="absolute top-3 right-3">
        <CloseIcon />
      </button>

      {/* Header Section */}
      <h2 className="text-lg font-semibold">User Profile</h2>

      {/* User Role */}
      <div className="my-2 flex items-center">
        <span className="text-gray-700 font-medium">User Role</span>
        <div className="ml-2 rounded-md px-3 py-1 text-sm font-bold gradient-text border border-gray-300">
          {user.personalDetails.roleType || "Manager"}
        </div>
      </div>

      {/* User Details Section */}
      <div className="my-6">
        <div className="grid grid-cols-2 gap-4 justify-items-end text-sm">
          <div className="justify-self-start">
            <div className="font-semibold">
              {user.personalDetails.firstName}{" "}
              {user.personalDetails.lastName || ""}
            </div>
            <div className="text-gray-500 text-right">
              {user.personalDetails.email || ""}
            </div>
          </div>
          <div>
            <div className="text-sm font-semibold">Created on</div>
            <div className="text-sm text-gray-500">
              {user.personalDetails.createdOn || "01 Sep 2024"}
            </div>
          </div>
          <div className="justify-self-start">
            <div className="font-semibold">
              Job Title
            </div>
            <div className="text-gray-500 text-right">
              {user.personalDetails.roleType || ""}
            </div>
          </div>
          <div>
            <div className="text-sm font-semibold">Department</div>
            <div className="text-sm text-gray-500">
              {user.personalDetails.department || ""}
            </div>
          </div>
        </div>
      </div>
      
      <hr className="my-6 text-gray-500" />

      <div className="my-6">
        <div className="font-semibold overflow-x-auto">
        <SecurityOutlined className="text-gray-500" />
        <span className="ml-2">Module Permissions</span></div>
        <div className="flex flex-wrap mt-2 ml-7">
          {user.permissions && user.permissions.collect && (
            <div className="flex items-center mr-4">
              <CheckCircleIcon fontSize="small text-green-600" />
              <span className="ml-1">Collect</span>
            </div>
          )}
          {user.permissions && user.permissions.analyse && (
            <div className="flex items-center mr-4">
              <CheckCircleIcon fontSize="small text-green-600" />
              <span className="ml-1">Analyse</span>
            </div>
          )}
          {user.permissions && user.permissions.report && (
            <div className="flex items-center mr-4">
              <CheckCircleIcon fontSize="small text-green-600" />
              <span className="ml-1">Report</span>
            </div>
          )}
          {user.permissions && user.permissions.optimise && (
            <div className="flex items-center mr-4">
              <CheckCircleIcon fontSize="small text-green-600" />
              <span className="ml-1">Optimise</span>
            </div>
          )}
          {user.permissions && user.permissions.track && (
            <div className="flex items-center mr-4">
              <CheckCircleIcon fontSize="small text-green-600" />
              <span className="ml-1">Track</span>
            </div>
          )}
        </div>
      </div>

      <hr className="my-6 text-gray-500" />

      {/* Organization Access Permissions */}
      <div className="my-6">
        <div className="font-semibold">
        <CorporateFareOutlined className="text-gray-500" />
        <span className="ml-2">Organization Access Permissions</span></div>
        <ul className="list-disc ml-12 text-gray-700 mt-3">
          {user.organizationDetails.organization?.map((org) => (
            <li key={org}>{org}</li>
          ))}
        </ul>
      </div>

      {/* Corporate Access Permissions */}
      <div className="my-6">
        <div className="font-semibold">
        <CorporateFareOutlined className="text-gray-500" />
        <span className="ml-2">Corporate Access Permissions</span></div>
        <ul className="list-disc ml-12 text-gray-700 mt-3">
          {user.organizationDetails.corporate?.map((corp) => (
            <li key={corp}>{corp}</li>
          ))}
        </ul>
      </div>

      {/* Locations Access Permissions */}
      <div className="my-6">
        <div className="font-semibold">
        <LocationOnOutlined className="text-gray-500" />
        <span className="ml-2">Locations Access Permissions</span></div>
        <ul className="list-disc ml-12 text-gray-700 mt-3">
          {user.organizationDetails.location?.map((location) => (
            <li key={location}>{location}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default UserProfile;
