import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {  useSearchParams } from "react-router-dom";
import { ChevronRight, Person } from "@mui/icons-material";

const PersonalDetailsForm = ({ onNext }) => {
  const [details, setDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    roleType: "",
    jobTitle: "",
    department: "",
    status: "Active",
  });

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onNext({ ...details });
  };

  // edit user
  const [searchParams] = useSearchParams();

  const edit = searchParams.get('edit') === 'true';
  const currentUser = useSelector(state => state.users.currentUser);

  useEffect(() => {
    if (edit && currentUser.personalDetails) {
      setDetails({
        firstName: currentUser.personalDetails.firstName || "",
        lastName: currentUser.personalDetails.lastName || "",
        email: currentUser.personalDetails.email || "",
        phoneNumber: currentUser.personalDetails.phoneNumber || "",
        roleType: currentUser.personalDetails.roleType || "",
        jobTitle: currentUser.personalDetails.jobTitle || "",
        department: currentUser.personalDetails.department || "",
        status: currentUser.personalDetails.status || "Active",
      });
    }
  }, [edit, currentUser]);

  return (
    <>
      <div className="flex justify-items-center items-center gap-2 mt-6 mb-2">
        <Person />
        <div className="text-[#0f1728] text-md font-medium font-['Manrope'] leading-7">
          Personal Details
        </div>
      </div>
      <div className="text-[#667084] text-sm font-normal font-['Manrope'] leading-tight mb-6">
        Please fill the personal details of the user.
      </div>
      <form onSubmit={handleSubmit} className="space-y-4">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label htmlFor="firstName" className="block text-sm font-medium text-gray-600">First Name*</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            placeholder="First Name"
            value={details.firstName}
            onChange={handleChange}
            className="form-input mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
          />
        </div>
        <div>
          <label htmlFor="lastName" className="block text-sm font-medium text-gray-600">Last Name*</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            placeholder="Last Name"
            value={details.lastName}
            onChange={handleChange}
            className="form-input mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
          />
        </div>
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-600">Work Email*</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Work Email"
            value={details.email}
            onChange={handleChange}
            className="form-input mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
          />
        </div>
        <div>
          <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-600">Phone Number</label>
          <input
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            placeholder="Phone Number"
            value={details.phoneNumber}
            onChange={handleChange}
            className="form-input mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
          />
        </div>
        <div>
          <label htmlFor="roleType" className="block text-sm font-medium text-gray-600">Role Type*</label>
          <select
            id="roleType"
            name="roleType"
            value={details.roleType}
            onChange={handleChange}
            className="form-select mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
          >
            <option value="">Select Role Type</option>
            <option value="Manager">Manager</option>
            <option value="Employee">Employee</option>
          </select>
        </div>
        <div>
          <label htmlFor="jobTitle" className="block text-sm font-medium text-gray-600">Job Title*</label>
          <input
            type="text"
            id="jobTitle"
            name="jobTitle"
            placeholder="Job Title"
            value={details.jobTitle}
            onChange={handleChange}
            className="form-input mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
          />
        </div>
        <div>
          <label htmlFor="department" className="block text-sm font-medium text-gray-600">Department*</label>
          <select
            id="department"
            name="department"
            value={details.department}
            onChange={handleChange}
            className="form-select mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
          >
            <option value="">Select Department</option>
            <option value="operations">Operations</option>
            <option value="hr">Human Resources</option>
          </select>
        </div>
      </div>
      <div className="absolute right-4 bottom-8">
      <button
        type="submit"
        className="mt-4 w-full bg-[#007eef] hover:translate-x-0.5 hover:-translate-y-0.5 hover:shadow-lg text-white font-bold py-2 px-4 rounded flex justify-center items-center gap-2 shadow"
      >
        <span className="text-xs font-['Manrope']">Next</span>
        <ChevronRight />
      </button>
      </div>
    </form>
    </>
  );
};

export default PersonalDetailsForm;
