import React from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Link } from "react-router-dom";

const UserAddedModal = ({addNewUser, edit=false}) => {
  return (
    <div className="bg-white shadow-lg rounded-lg p-6 max-w-lg min-w-[444px] mx-auto">
      <div className="flex items-center space-x-2 mb-2">
        <CheckCircleIcon className="text-green-500" fontSize="medium" />
        <h2 className="text-lg font-semibold">{edit ? 'User details saved' : 'New User Added'}</h2>
      </div>
      <p className="text-gray-600 mb-6 text-md">Select view all users to view or edit details of this user.</p>
      <div className="flex justify-between items-center mt-8">
        <div className="flex items-center space-x-2">
          <span className="text-blue-600">
            {/* <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 7h18M3 12h18m-7 5h7" />
            </svg> */}
          </span>
          <Link to="/home" className="text-blue-600 hover:underline">Sustainext HQ</Link>
        </div>
        <div className="flex space-x-2 text-md">
          <Link to='/users/manage' className="bg-white hover:bg-gray-100 text-gray-700 px-4 py-1 rounded-lg border border-gray-10">View all Users</Link>
          <button onClick={addNewUser} className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-1 rounded-lg">Add User +</button>
        </div>
      </div>
    </div>
  );
};

export default UserAddedModal;