import React, { useEffect, useState } from 'react';

const PermissionToggle = ({ label, description, enabled, onChange }) => {
  // Use internal state to track changes, initialized from the prop
  const [isChecked, setIsChecked] = useState(enabled);

  // Update internal state and call onChange when the checkbox is toggled
  const handleToggle = () => {
    setIsChecked(!isChecked);
    onChange(); // Propagate the change to the parent component
  };

  useEffect(() => {
    setIsChecked(enabled);
  }, [enabled]);
  
  return (
    <div className="flex items-start gap-2 mb-5">
      <label className="switch mt-1.5">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleToggle}
          className="mt-1 accent-green-500 toggle-checkbox"
        />
        <span className="slider round"></span>
      </label>

      <div>
        <h3 className="font-semibold">{label}</h3>
        <p className="text-sm text-gray-600">{description}</p>
      </div>
    </div>
  );
};

export default PermissionToggle;
