import React from "react";
import {
  Factory,
  DeleteOutline,
  Tungsten,
  Water,
  EmojiNature,
} from "@mui/icons-material";
import material from "../../assets/images/material.svg";
import { useProSidebar } from "react-pro-sidebar";

const Aside = ({ activeTab, handleTabClick }) => {
  return (
    <div className={`m-3 ml-2 p-2 border border-r-2 border-b-2 shadow-lg rounded-lg sticky top-[5rem]`}>
      <div className="flex items-start py-4 min-w-[200px] min-h-[100vh] rounded-lg text-[0.875rem]">
        <div className="flex flex-col w-full font-medium">
          <div className="flex-col justify-end items-start gap-2 inline-flex mb-8">
            <div className="text-neutral-500 text-[10px] font-normal font-['Manrope'] uppercase leading-none tracking-wide">
              Analyse
            </div>
            <div className="text-neutral-500 text-base font-semibold font-['Manrope'] leading-none">
              Environment
            </div>
          </div>
          <button
            className={`flex items-center justify-start px-2 py-2 mb-2 focus:outline-none w-full ${
              activeTab === "Emissions"
                ? "text-[#007EEF] border-l-4 border-[#007EEF]"
                : "bg-transparent text-[#727272] hover:bg-blue-400 hover:text-white"
            }`}
            onClick={() => handleTabClick("Emissions")}
          >
            <Factory className="w-5 h-5 mr-5" />
            <span className="mr-7">Emissions</span>
          </button>
          <div>
            <button
              className={`flex items-center justify-start px-2 py-2 mb-2 focus:outline-none w-full ${
                activeTab === "Energy"
                  ? "text-[#007EEF] border-l-4 border-[#007EEF]"
                  : "bg-transparent text-[#727272] hover:bg-blue-400 hover:text-white"
              }`}
              onClick={() => handleTabClick("Energy")}
            >
              <Tungsten className="w-5 h-5 mr-5" />
              <span className="mr-12">Energy</span>
              <div className="inset-y-0 -right-2 flex items-center pointer-events-none"></div>
            </button>
          </div>

          {/* <button
            className={`flex items-center justify-start px-2 py-2 mb-2 focus:outline-none w-full ${
              activeTab === "Waste"
                ? "text-[#007EEF] border-l-4 border-[#007EEF]"
                : "bg-transparent text-[#727272] hover:bg-blue-400 hover:text-white"
            }`}
            onClick={() => handleTabClick("Waste")}
          >
            <DeleteOutline className="w-5 h-5 mr-5" />
            <span className="mr-12">Waste</span>
          </button>
          <button
            className={`flex items-center justify-start px-2 py-2 mb-2 focus:outline-none w-full ${
              activeTab === "Materials"
                ? "text-[#007EEF] border-l-4 border-[#007EEF]"
                : "bg-transparent text-[#727272] hover:bg-blue-400 hover:text-white"
            }`}
            onClick={() => handleTabClick("Materials")}
          >
            <img src={material} className="w-5 h-5 mr-5" />
            <span className="mr-8">Materials</span>
          </button>
          <button
            className={`flex items-center justify-start px-2 py-2 mb-2 focus:outline-none w-full ${
              activeTab === "Water and effluents"
                ? "text-[#007EEF] border-l-4 border-[#007EEF]"
                : "bg-transparent text-[#727272] hover:bg-blue-400 hover:text-white"
            }`}
            onClick={() => handleTabClick("Water and effluents")}
          >
            <Water className="w-5 h-5 mr-5" />
            <span className="">Water and effluents</span>
          </button>
          <button
            className={`flex items-center justify-start  px-2 py-2 mb-2 focus:outline-none ${
              activeTab === "Bio diversity"
                ? "text-[#007EEF] border-l-4 border-[#007EEF]"
                : "bg-transparent text-[#727272] hover:bg-blue-400 hover:text-white"
            }`}
            onClick={() => handleTabClick("Bio diversity")}
          >
            <EmojiNature className="w-5 h-5 mr-5" />
            <span className="mr-4">Bio diversity</span>
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default Aside;
