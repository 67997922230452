import './App.css';
// import { useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Layout from 'pages/layout/Layout';
import Environment from 'pages/Environment';
import Home from 'pages/Home';
import MaterialityDashboard from 'pages/Materiality Dashboard';
import Report from 'pages/Report';
import Reportform from 'pages/Report/reportform';
import Ghgtemplates from 'pages/Report/report-templates/ghgtemplates';
import CreateScenariosForm from './pages/optimize/Create-Scenarios/Create-Scenarios-form';
import CreateScenarios from './pages/optimize/Create-Scenarios';
import Track from 'pages/Track';
import Structure from 'pages/general/structure';
import Organization from 'pages/general/setup/Organization';
import Corporate from 'pages/general/setup/Entity';
import Location from 'pages/general/setup/Location';
import Preferences from 'pages/general/setup/Organization/Preferences';
import Login from 'pages/auth/Login2';
import Register from 'pages/auth/Register2';
import Analyse from 'pages/Analyse';
import Governance from 'pages/governance/Governance';
import General from 'pages/additional-general/General';
import Social from 'pages/social/Social';
import Economic from 'pages/economic/Economic';
import About from 'pages/About';
import SDGCards from 'pages/general/setup/Organization/Preferences/SDGCards';
import Locations from 'pages/general/structure/Locations';
import Forgotpassword from 'pages/auth/forgot-password';
import Email from 'pages/auth/email';
import Passwordreset from 'pages/auth/password-reset';
import SustainextHQ from 'pages/Home/useronboarding';
import HomeClone from 'pages/Home/homeClone';
import VerticalCarousel from 'pages/auth/signup-flow';
import Clintpasswordreset from 'pages/auth/clint_password_reset';
import DynamicForm from 'pages/demo/DynamicForm';
import AddNewUser from 'pages/Users';
import ManageUsers from 'pages/Users/ManageUsers';
function App() {
  return (
    <div className='App'>
      <BrowserRouter basename={process.env.ROUTER_BASE || '/'}>
        <Routes>
          <Route element={<Layout />}>
            <Route path='/' element={<Navigate to='/login' />} />
            <Route path='home' element={<Home />} />
            {/* <Route path='home' element={<Navigate to='index' />} /> */}
            <Route path='home/sustainextHQ' element={<HomeClone />} />
            <Route
              path='materiality-dashboard'
              element={<MaterialityDashboard />}
            />

            <Route path='collect' element={<Navigate to='environment' />} />
            <Route path='collect/environment' element={<Environment />} />

            {/* Testing */}
            {/* <Route path="dynamicform" element={<DynamicForm/>} /> */}
            {/* <Route path="general/structure/location" element={<Location />} /> */}

            {/* TEsting */}

            <Route path='collect' element={<Navigate to='general' />} />
            <Route path='collect/general' element={<General />} />

            <Route path='collect' element={<Navigate to='governance' />} />
            <Route path='collect/governance' element={<Governance />} />
            <Route path='analyse' element={<Analyse />} />
            <Route path='report' element={<Report />} />
            <Route path='report/new' element={<Reportform />} />
            <Route path='report/GHGtemplate' element={<Ghgtemplates />} />
            <Route path='optimise' element={<CreateScenarios />} />
            <Route
              path='optimise/create-scenarios'
              element={<CreateScenariosForm />}
            />

            <Route path='track' element={<Track />} />
            <Route path='collect' element={<Navigate to='social' />} />
            <Route path='collect/social' element={<Social />} />

            <Route path='collect' element={<Navigate to='economic' />} />
            <Route path='collect/economic' element={<Economic />} />

            <Route
              path='general'
              element={<Navigate to='setup/organization' />}
            />
            <Route path='general/structure' element={<Structure />} />
            <Route
              path='home/dashboard/preferences'
              element={<Preferences />}
            />
            <Route path='home/dashboard' element={<Home />} />
            <Route
              path='general/structure/organization'
              element={<Organization />}
            />
            <Route path='general/structure/corporate' element={<Corporate />} />
            <Route path='general/structure/location' element={<Location />} />
            <Route path='about' element={<About />} />
            <Route path='users/add' element={<AddNewUser />} />
            <Route path='users/manage' element={<ManageUsers />} />
          </Route>
          {/* Non -Private routes */}
          <Route path='login' element={<Login />} />
          {/* <Route path='register' element={<Register />} /> */}
          <Route path='register' element={<VerticalCarousel />} />

          <Route path='email/confirm/:key' element={<Email />} />
          <Route path='forgotten-password' element={<Forgotpassword />} />
          <Route
            path='password-reset/confirm/:uid/:token'
            element={<Passwordreset />}
          />
             <Route
            path='reset-password'
            element={<Clintpasswordreset />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
