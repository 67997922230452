import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
  menuClasses,
} from 'react-pro-sidebar';
import { Tooltip } from 'react-tooltip';
import WhiteOnBlueLogo from '../assets/logos/WhiteOnBlueLogo.png';
import favicon from '../assets/favicons/apple-touch-icon.png';
import {
  Diversity2,
  Diversity3,
  HomeOutlined,
  InfoOutlined,
  KeyboardDoubleArrowLeft,
  ChevronRight,
  PersonAddAltOutlined,
  ManageAccountsOutlined,
} from '@mui/icons-material';
import { PieChartOutlineOutlined } from '@mui/icons-material';
import { AddBoxOutlined } from '@mui/icons-material';
import { PublicOutlined } from '@mui/icons-material';
import { BarChartOutlined } from '@mui/icons-material';
import { EditNoteOutlined } from '@mui/icons-material';
import { SettingsSuggestOutlined } from '@mui/icons-material';
import { SearchOutlined } from '@mui/icons-material';
import { AccountTreeOutlined } from '@mui/icons-material';
import { SettingsOutlined } from '@mui/icons-material';
import { Diversity1Outlined } from '@mui/icons-material';
import { GroupOutlined } from '@mui/icons-material';

const Sidebar1 = () => {
  const { collapseSidebar, collapsed } = useProSidebar();
  const [active, setActive] = useState('Home');

  const envClickHandler = () => {
    setActive('Collect');
    if (!collapsed) {
      collapseSidebar();
      setActive('Collect');
    }
  };

  const preClickHandler = () => {
    if (collapsed) {
      collapseSidebar();
      setActive('Collect');
    }
  };

  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        display: 'flex',
        height: '100%',
        minHeight: '100vh',
        zIndex: '1000',
      }}
    >
      <Sidebar
        transitionDuration={400}
        backgroundColor='#0a0528'
        collapsedWidth='5rem'
      >
        {collapsed ? (
          <div
            className='relative flex justify-center items-center my-6 cursor-pointer'
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <img style={{ width: '42px' }} src={favicon} alt='logo' />
            <div
              className='sb-button absolute right-0 top-0'
              onClick={() => collapseSidebar()}
            >
              <div className='my-2 cursor-pointer flex'>
                <ChevronRight style={{ color: 'white' }} />
                {isHovered ? (
                  <ChevronRight
                    style={{
                      color: 'white',
                      position: 'absolute',
                      right: '-3px',
                      top: '11px',
                      fontSize: '18px',
                    }}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        ) : (
          <div style={{ display: 'flex' }}>
            <img style={{ width: '200px' }} src={WhiteOnBlueLogo} alt='logo' />
            <div className='sb-button' onClick={() => collapseSidebar()}>
              <div className='my-8 cursor-pointer'>
                <KeyboardDoubleArrowLeft style={{ color: 'white' }} />
              </div>
            </div>
          </div>
        )}

        <Menu
          className='text-sm'
          menuItemStyles={{
            button: ({ level, active, disabled }) => {
              // only apply styles on first level elements of the tree
              if (level === 0)
                return {
                  color: active ? '#fff' : '#fff',
                  backgroundColor: active ? '#081746' : undefined,
                  ':hover': {
                    backgroundImage:
                      'linear-gradient(rgba(0, 126, 239, 1)) 0%,rgba(0, 126, 239, 1) 0%, rgba(0, 126, 239, 1) 100%)',
                  },
                  margin: '0 0.6rem',
                  borderRadius: '0.5rem',
                  padding: '0 12px',
                };
            },
          }}
        >
          <MenuItem
            active={active === 'Home'}
            onClick={() => setActive('Home')}
            icon={<HomeOutlined />}
            component={<Link to='/home' />}
          >
            <Tooltip
              id='sidebarTooltip'
              className='absolute rounded py-1 px-2 leading-5'
            />
            Sustainext HQ
          </MenuItem>

          <MenuItem
            className='materilaity-dashboard'
            active={active === 'Materiality Dashboard'}
            onClick={() => setActive('Materiality Dashboard')}
            icon={
              collapsed ? (
                <div>
                  <a
                    data-tooltip-id='sidebarTooltip2'
                    data-tooltip-html='Materiality<br/>Dashboard'
                    data-tooltip-variant='light'
                  >
                    <PieChartOutlineOutlined />
                  </a>
                  <Tooltip
                    id='sidebarTooltip2'
                    className='absolute rounded py-1 px-2 leading-5'
                  />
                </div>
              ) : (
                <PieChartOutlineOutlined />
              )
            }
            component={<Link to='/materiality-dashboard' />}
          >
            Materiality Dashboard
          </MenuItem>

          <hr className=' bg-[rgba(217, 217, 217, 1)] h-[0.0625rem] my-4 mx-3 opacity-30' />

          <SubMenu
            active={active === 'Collect'}
            label='Collect'
            style={{ userSelect: 'none' }}
            icon={
              collapsed ? (
                <div>
                  <a
                    data-tooltip-id='sidebarTooltipCollect'
                    data-tooltip-content='Collect'
                    data-tooltip-variant='light'
                  >
                    <AddBoxOutlined />
                  </a>
                  <span className='font-normal'>
                    <Tooltip
                      id='sidebarTooltipCollect'
                      className='rounded p-0 px-2'
                    />
                  </span>
                </div>
              ) : (
                <AddBoxOutlined />
              )
            }
            rootStyles={{
              ['& > .' + menuClasses.button]: {
                zIndex: 10000,
                '&:hover': {
                  color: 'white',
                  backgroundColor: 'rgba(10, 5, 40, 1)',
                },
              },
              ['.' + menuClasses.subMenuContent]: {
                color: 'white',
                background: 'rgba(10, 5, 40, 1)',
                borderRadius: '0.5rem',
                fontSize: '0.8rem',
              },
            }}
          >
            <MenuItem
              icon={<PublicOutlined />}
              component={<Link to='/collect/environment' />}
              onClick={envClickHandler}
            >
              {' '}
              Environment
            </MenuItem>
            <MenuItem
              component={<Link to='/collect/social' />}
              onClick={envClickHandler}
              icon={
                collapsed ? (
                  <div>
                    <a
                      data-tooltip-id='sidebarTooltip'
                      data-tooltip-content='Social'
                      data-tooltip-variant='light'
                    >
                      <GroupOutlined />
                    </a>
                    <span className='font-normal'>
                      <Tooltip
                        id='sidebarTooltip'
                        className='rounded p-0 px-2 me-1'
                      />
                    </span>
                  </div>
                ) : (
                  <GroupOutlined />
                )
              }
            >
              {' '}
              Social
            </MenuItem>
            <MenuItem
              component={<Link to='/collect/governance' />}
              onClick={envClickHandler}
              icon={
                collapsed ? (
                  <div>
                    <a
                      data-tooltip-id='sidebarTooltip'
                      data-tooltip-content='Governance'
                      data-tooltip-variant='light'
                    >
                      <Diversity1Outlined />
                    </a>
                    <span className='font-normal'>
                      <Tooltip
                        id='sidebarTooltip'
                        className='rounded p-0 px-2 me-1'
                      />
                    </span>
                  </div>
                ) : (
                  <Diversity1Outlined />
                )
              }
            >
              {' '}
              Governance
            </MenuItem>
            <MenuItem
              component={<Link to='/collect/general' />}
              onClick={envClickHandler}
              icon={
                collapsed ? (
                  <div>
                    <a
                      data-tooltip-id='sidebarTooltip'
                      data-tooltip-content='General'
                      data-tooltip-variant='light'
                    >
                      <Diversity2 />
                    </a>
                    <span className='font-normal'>
                      <Tooltip
                        id='sidebarTooltip'
                        className='rounded p-0 px-2 me-1'
                      />
                    </span>
                  </div>
                ) : (
                  <Diversity2 />
                )
              }
            >
              {' '}
              General
            </MenuItem>
            <MenuItem
              component={<Link to='/collect/economic' />}
              onClick={envClickHandler}
              icon={
                collapsed ? (
                  <div>
                    <a
                      data-tooltip-id='sidebarTooltip'
                      data-tooltip-content='economic'
                      data-tooltip-variant='light'
                    >
                      <Diversity2 />
                    </a>
                    <span className='font-normal'>
                      <Tooltip
                        id='sidebarTooltip'
                        className='rounded p-0 px-2 me-1'
                      />
                    </span>
                  </div>
                ) : (
                  <Diversity3 />
                )
              }
            >
              {' '}
              Economic
            </MenuItem>
          </SubMenu>
          <MenuItem
            className='analyse-section'
            active={active === 'Analyse'}
            onClick={() => setActive('Analyse')}
            component={<Link to='/analyse' />}
            icon={
              collapsed ? (
                <div>
                  <a
                    data-tooltip-id='sidebarTooltipAnalyse'
                    data-tooltip-content='Analyse'
                    data-tooltip-variant='light'
                  >
                    <BarChartOutlined />
                  </a>
                  <span className='font-normal'>
                    <Tooltip
                      id='sidebarTooltipAnalyse'
                      className='rounded p-0 px-2 me-1'
                    />
                  </span>
                </div>
              ) : (
                <BarChartOutlined />
              )
            }
          >
            Analyse
          </MenuItem>
          <MenuItem
            className='report-section'
            active={active === 'Report'}
            onClick={() => setActive('Report')}
            component={<Link to='/report' />}
            icon={
              collapsed ? (
                <div>
                  <a
                    data-tooltip-id='sidebarTooltipReport'
                    data-tooltip-content='Report'
                    data-tooltip-variant='light'
                  >
                    <EditNoteOutlined />
                  </a>
                  <span className='font-normal'>
                    <Tooltip
                      id='sidebarTooltipReport'
                      className='rounded p-0 px-2 me-1'
                    />
                  </span>
                </div>
              ) : (
                <EditNoteOutlined />
              )
            }
          >
            Report
          </MenuItem>
          <MenuItem
            className='optimize-section'
            active={active === 'Optimise'}
            onClick={() => setActive('Optimise')}
            component={<Link to='/optimise' />}
            icon={
              collapsed ? (
                <div>
                  <a
                    data-tooltip-id='sidebarTooltipOptimise'
                    data-tooltip-content='Optimise'
                    data-tooltip-variant='light'
                  >
                    <SettingsSuggestOutlined />
                  </a>
                  <span className='font-normal'>
                    <Tooltip
                      id='sidebarTooltipOptimise'
                      className='rounded p-0 px-2 me-1'
                    />
                  </span>
                </div>
              ) : (
                <SettingsSuggestOutlined />
              )
            }
          >
            {' '}
            Optimise
          </MenuItem>
          <MenuItem
            className='track-section'
            active={active === 'Track'}
            onClick={() => setActive('Track')}
            component={<Link to='/track' />}
            icon={
              collapsed ? (
                <div>
                  <a
                    data-tooltip-id='sidebarTooltipTrack'
                    data-tooltip-content='Track'
                    data-tooltip-variant='light'
                  >
                    <SearchOutlined />
                  </a>
                  <span className='font-normal'>
                    <Tooltip
                      id='sidebarTooltipTrack'
                      className='rounded p-0 px-2 me-1'
                    />
                  </span>
                </div>
              ) : (
                <SearchOutlined />
              )
            }
          >
            Track
          </MenuItem>

          <hr className='bg-[rgba(217, 217, 217, 1)] h-[0.0625rem] my-4 mx-3 opacity-30' />

          <SubMenu
            active={active === 'Users'}
            label='Users'
            style={{ userSelect: 'none' }}
            icon={
              collapsed ? (
                <div>
                  <a
                    data-tooltip-id='sidebarTooltipCollect'
                    data-tooltip-content='Users'
                    data-tooltip-variant='light'
                  >
                    <GroupOutlined />
                  </a>
                  <span className='font-normal'>
                    <Tooltip
                      id='sidebarTooltipCollect'
                      className='rounded p-0 px-2'
                    />
                  </span>
                </div>
              ) : (
                <GroupOutlined />
              )
            }
            rootStyles={{
              ['& > .' + menuClasses.button]: {
                zIndex: 10000,
                '&:hover': {
                  color: 'white',
                  backgroundColor: 'rgba(10, 5, 40, 1)',
                },
              },
              ['.' + menuClasses.subMenuContent]: {
                color: 'white',
                background: 'rgba(10, 5, 40, 1)',
                borderRadius: '0.5rem',
                fontSize: '0.8rem',
              },
            }}
          >
            <MenuItem
              icon={<PersonAddAltOutlined />}
              component={<Link to='/users/add' />}
              // onClick={envClickHandler}
            >
              {' '}
              Add new user
            </MenuItem>
            <MenuItem
              icon={<ManageAccountsOutlined />}
              component={<Link to='users/manage' />}
              // onClick={envClickHandler}
            >
              {' '}
              Manage users
            </MenuItem>
          </SubMenu>

          <MenuItem
            active={active === 'Organizational Structure'}
            onClick={() => setActive('Organizational Structure')}
            icon={<AccountTreeOutlined />}
            component={<Link to='/general/structure' />}
          >
            {' '}
            Organizational Structure
          </MenuItem>

          <MenuItem
            active={active === 'About'}
            onClick={() => setActive('About')}
            component={<Link to='/about' />}
            icon={
              collapsed ? (
                <div>
                  <a
                    data-tooltip-id='sidebarTooltipAbout'
                    data-tooltip-content='About'
                    data-tooltip-variant='light'
                  >
                    <InfoOutlined />
                  </a>
                  <span className='font-normal'>
                    <Tooltip
                      id='sidebarTooltipAbout'
                      className='rounded p-0 px-2 me-1'
                    />
                  </span>
                </div>
              ) : (
                <InfoOutlined />
              )
            }
          >
            About
          </MenuItem>
          
        </Menu>
      </Sidebar>
      {/* {collapsed && (
        <div
          className="relative top-7 right-4 bg-sky-500 p-0.5 pb-1 h-7 z-50 rounded-full cursor-pointer"
          onClick={() => collapseSidebar()}
        >
          <KeyboardArrowRightOutlined style={{ color: "white" }} />
        </div>
      )} */}
    </div>
  );
};

export default Sidebar1;