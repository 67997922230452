import React, { useEffect, useState } from "react";
import { ChevronLeft, ChevronRight, Person } from "@mui/icons-material";
import CheckboxTable from "../common/CheckboxTable";
import axios from "axios";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

const OrganizationDetailsForm = ({ onNext, onPrev }) => {
  const [hierarchy, setHierarchy] = useState([]);
  const [filteredCorporates, setFilteredCorporates] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);

  const [selections, setSelections] = useState({
    organization: [],
    corporate: [],
    location: [],
  });

  const handleToggle = (category, value) => {
    setSelections((prev) => {
      const newSelections = {
        ...prev,
        [category]: prev[category].includes(value)
          ? prev[category].filter((item) => item !== value)
          : [...prev[category], value],
      };

      // Update filtered corporates when organizations change
      if (category === "organization") {
        const newFilteredCorporates = filterCorporates(hierarchy).filter((corp) =>
          newSelections.organization.includes(corp.parent)
        );
        setFilteredCorporates(newFilteredCorporates);
        // Clear corporate and location selections when organizations change
        newSelections.corporate = [];
        newSelections.location = [];
      }

      // Update filtered locations when corporates change
      if (category === "corporate") {
        const newFilteredLocations = filterLocations(hierarchy).filter((loc) =>
          newSelections.corporate.includes(loc.parent)
        );
        setFilteredLocations(newFilteredLocations);
        // Clear location selections when corporates change
        newSelections.location = [];
      }

      return newSelections;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onNext({ ...selections });
  };

  const token = useSelector((state) => state.global.authToken);

  let axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-type": "application/json",
    },
  };

  function filterLocations(data) {
    return data.flatMap((item) =>
      item.corporatenetityorg.flatMap((entity) =>
        entity.location.map((location) => ({
          id: location.id,
          name: location.name,
          Address: location.address,
          Country: location.country,
          state: location.state,
          revenue: location.revenue,
          amount: location.amount,
          area: location.area,
          sector: location.sector,
          City: location.city,
          parent: entity.name,
          businessActivities: location.type_of_business_activities,
          productTypes: location.type_of_product,
          serviceTypes: location.type_of_services,
        }))
      )
    );
  }

  function filterCorporates(data) {
    return data.flatMap((org) =>
      org.corporatenetityorg.map((corp) => ({
        id: corp.id,
        name: corp.name,
        revenue: corp.revenue,
        employeecount: corp.employeecount,
        sector: corp.sector,
        Country: corp.Country,
        state: corp.state,
        city: corp.city,
        Address: corp.address,
        businessActivities: corp.type_of_business_activities,
        productTypes: corp.type_of_product,
        serviceTypes: corp.type_of_services,
        parent: org.name,
        locations: corp.location.map((loc) => ({
          id: loc.id,
          name: loc.name,
          Address: loc.streetaddress,
          Country: loc.country,
          state: loc.state,
          City: loc.city,
          revenue: loc.revenue,
          amount: loc.amount,
          area: loc.area,
          sector: loc.sector,
        })),
      }))
    );
  }

  function fetchHierarchy() {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/structure`, axiosConfig)
      .then((response) => {
        const data = response.data;
        setHierarchy(data);
        setFilteredCorporates([]); // Initially empty
        setFilteredLocations([]); // Initially empty
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }

  useEffect(() => {
    fetchHierarchy();
  }, []);

  const [searchParams] = useSearchParams();

  const edit = searchParams.get('edit') === 'true';
  const currentUser = useSelector(state => state.users.currentUser);

  useEffect(() => {
    fetchHierarchy();
    if (edit && currentUser.organizationDetails) {
      const { organization, corporate, location } = currentUser.organizationDetails;
      setSelections({
        organization: organization || [],
        corporate: corporate || [],
        location: location || [],
      });
    }
  }, [edit, currentUser]);

  return (
    <div className="py-6">
      <div className="flex items-center gap-2 mb-4">
        <Person className="w-6 h-6" />
        <h2 className="text-lg font-medium">Organization Details</h2>
      </div>
      <p className="text-sm text-gray-600 mb-6">
        Select the relevant Organization, Corporate, and Location from the list.
        This will map the user to the selected hierarchy.
      </p>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <CheckboxTable
            title="Select Organizations"
            options={hierarchy}
            onToggle={(item) => handleToggle("organization", item)}
            selections={selections.organization}
            isParent={false}
            tooltipContent={`This list is generated from the Organisation Structure section. <br />
            You can select one or more organizations to map new users. <br />
            This selection determines which organizations the users can <br />
            interact with.`}
          />
          <CheckboxTable
            title="Select Corporates"
            options={filteredCorporates}
            onToggle={(item) => handleToggle("corporate", item)}
            selections={selections.corporate}
            isParent={true}
            tooltipContent={`This list is generated based on the organization selected <br />
            in the previous column. You may select one or more corporates. <br />
            This selection determines which corporates the <br />
            users can interact with.`}
          />
          <CheckboxTable
            title="Select Locations"
            options={filteredLocations}
            onToggle={(item) => handleToggle("location", item)}
            selections={selections.location}
            isParent={true}
            tooltipContent={`This list is generated based on the Corporate <br />
            selected in the previous column. <br />
            You may select one or more Locations. <br />
            This selection determines which Locations <br />
            the users can interact with.`}
          />
        </div>
        <div className="flex justify-end items-center mt-[4rem] gap-1">
          <button
            onClick={onPrev}
            className="flex items-center gap-2 px-4 py-2 text-gray-600"
          >
            <ChevronLeft className="w-4 h-4" />
            <span>Previous</span>
          </button>
          <button
            type="submit"
            className="bg-[#007eef] hover:translate-x-0.5 hover:-translate-y-0.5 hover:shadow-lg text-white font-bold py-2 px-4 rounded flex justify-center items-center gap-2 shadow"
          >
            <span className="text-xs font-['Manrope']">Next</span>
            <ChevronRight />
          </button>
        </div>
      </form>
    </div>
  );
};

export default OrganizationDetailsForm;